import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Sales Representative",
          "Customer Service Pro",
          "Data Analyst",
          "JavaScript Developer",
          "React Developer",
          "Tech Enthusiast",
          "Tech Blogger",
          "Full Stack Developer",
          "Deep Learning Engineer",
          "MERN Stack Developer",
          "Open Source Contributor",
          "Python Programmer",
          "GIS Specialist"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
