import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "justify" }}>
  <br />I am Kadeem Pratt, a seasoned business development specialist known for delivering 5-star customer service. Throughout my career, I've gained diverse experience by collaborating with companies operating in multiple business units and have successfully navigated various industries, including sales, data analytics, fitness, and GIG work. My professional journey has taken me through roles as a manager and a self-driven freelancer, demonstrating my adaptability and versatility. 

  <br />
  <br/>What sets me apart is my insatiable curiosity and adventurous approach to learning. I am a self-starter with an unwavering passion for achieving excellence and success. My commitment to professionalism and high performance is evident in my experience, notably with Acumen, where I thrived in internal-facing roles, managing teams of talented professionals. When in client-facing positions, I excelled, with my performance consistently measured against KPI metrics, resulting in a high-performing team player.
  <br/>
  <br />I am deeply passionate about forging relationships, breaking down communication barriers, and creating value that benefits all stakeholders, leading to positive-sum gains. My natural curiosity drives me to continually learn and grow, making me a valuable asset to any team. My mission is to join a dynamic and fast-paced environment that provides opportunities for upward mobility, skills training, and leadership development. In the near future, I envision myself at the helm of a high-performing sales team, and eventually, a department.
  <br/>
  <br />Beyond my professional experience, I lead a fulfilling life that revolves around actively contributing to my community and supporting causes focused on promoting a healthy planet and lifestyles. As a yoga instructor and practicing yogi, I nurture a wellness community with members from various backgrounds. Additionally, I indulge my passion for gardening and strategy board games, embodying a well-rounded approach to life.  <br/>
  <br />
 </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Board Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> YOGA
            </li>
            <li className="about-activity">
              <ImPointRight /> Cycling and Hiking
            </li>
            <li className="about-activity">
              <ImPointRight /> Creating Music Playlist
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "A free and open Internet is real equality"{" "}
          </p>
          <footer className="blockquote-footer">Kadem</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
