import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import tictactoe from "../../Assets/Projects/tictactoe.png";
import yoga from "../../Assets/yoga.png";
import noteapp from "../../Assets/Projects/IMG_6590.jpg";
import spaceX from "../../Assets/Projects/Screenshot 2023-03-31 092857.png";
//import suicide from "../../Assets/Projects/suicide.png";
import bostonhousing from "../../Assets/Projects/Screenshot 2023-04-20 225945.png";
// import conpay from "../../Assets/Projects/consumerpayment.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
          *note, this is not an exhausted list of my projects. Interested in a DEMO, please 
          message me on LinkedIn. 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={spaceX}
              isBlog={false}
              title="SpaceX EDA"
              description="Launch Sites Locations Analysis with Folium
              The launch success rate may depend on many factors such as payload mass, orbit type, and so on. It may also depend on the location and proximities of a launch site, i.e., the initial position of rocket trajectories. Finding an optimal location for building a launch site certainly involves many factors and hopefully we could discover some of the factors by analyzing the existing launch site locations."
              ghLink="https://github.com/Scoubershare/spaceX/blob/25d970d2d3668902bf3df7038ea6a0ccd6cf403c/lab_jupyter_launch_site_location%20(prof).ipynb"
              //demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bostonhousing}
              isBlog={false}
              title="Boston Housing Regression"
              description="Can we conclude that there is no relationship between Nitric oxide concentrations and proportion of non-retail business acres per town?

              No, we cannot conclude that there is no relationship between Nitric oxide concentrations (NOX) and proportion of non-retail business acres per town (INDUS) based on the data above. The OLS regression results show that the coefficient for INDUS is statistically significant (p-value < 0.05)....."
              ghLink="https://github.com/Scoubershare/DS-Stats/blob/5c7b634dd4d89bba5c93ef33ca2304b0388dfd84/Boston%20Housing%20Regression.ipynb"
              //demoLink=""
            />
          </Col>

         <Col md={4} className="project-card">
            <ProjectCard
              imgPath={noteapp}
              isBlog={false}
              title="Virtual Pet Web Browser Game Development (DEMO)"
              description="A game to care for a virtual pet (DEMO *Updates/ New Versions are pushed Weekly)."
              ghLink="https://github.com/Scoubershare/authapp.git"
              demoLink="https://graphqlserverintergreate.d2hyjxn0s4gf1a.amplifyapp.com/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tictactoe}
              isBlog={false}
              title="Tic-Tac-Toe" 
              description="The Tic Tac Toe game created in React.js is an engaging and interactive implementation of the classic game that brings nostalgic fun to players of all ages. Built using the popular React.js library, this game offers a modern and intuitive user interface, providing an enjoyable gaming experience."
              demoLink="https://master.d1f88s2hf52jx0.amplifyapp.com/"
            />
          </Col>

           <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yoga}
              isBlog={false}
              title="Yoga Posture Clinic"
              description="As a practicing yogi and instructor I link to build sequences on the go, so I am creating a yoga posture clinic with over 100 postures for instructors and students seeking inspiration for their practice. This is a work in progress, but I am excited to share the progress with you all."
               demoLink="https://master.d2fcztku8nehn2.amplifyapp.com/" 
            />
          </Col> 

           {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={conpay}
              isBlog={false}
              title="Fiance Dept Dashboard"
              description="This is a React.js UI design for a finance dashboard with payment updates. It is a comprehensive and visually appealing application that offers a powerful and intuitive user interface for managing financial information.
              This React.js project focuses on providing a feature-rich finance dashboard that enables users to monitor their financial activities, track payments, and stay updated with the latest transactions. The UI design is modern, sleek, and responsive, ensuring a seamless user experience across different devices and screen sizes."
               demoLink="https://main.d1rtey7o5ugfzz.amplifyapp.com/"  
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
